const calculateTieBreakerDigits = (nonZeroNumber: number) =>
  (255 - nonZeroNumber) / 255

export function getHashScore(hash: string) {
  if (!hash) return 0

  const zeros = countLeadingZeros(hash)

  const nextNonZeroByte = hash.slice(zeros, zeros + 2)
  const nextNonZeroNumber = hexToNumber(nextNonZeroByte)

  return zeros + calculateTieBreakerDigits(nextNonZeroNumber)
}

/** Compare two proofs and return the one with the most leading zeros
 * If both proofs have the same number of leading zeros, compare the next non-zero byte.
 * If both proofs have the same number of leading zeros and the same next non-zero byte, return the first one.
 */
export function getWinningHash(a: string, b: string) {
  const aScore = getHashScore(a)
  const bScore = getHashScore(b)

  if (bScore > aScore) {
    return { winner: b, score: bScore }
  }

  return { winner: a, score: aScore }
}

const hexToNumber = (hex: string) =>
  !hex ? 0 : Number(`0x${hex.padStart(2, '0')}`)

export function countLeadingZeros(hash: string) {
  if (!hash) return 0

  for (let i = 0; i < hash.length; i++) {
    if (hash[i] !== '0') {
      return i
    }
  }
  return hash.length
}
