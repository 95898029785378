import { toast } from '@/components/ui/use-toast'
import { api } from '@/lib/api'
import { formatDateLong, formatFileSize, mhtmlToHtml } from '@/lib/formatting'

import {
  CalendarIcon,
  ChevronsUpIcon,
  CircleXIcon,
  CopyIcon,
  DownloadIcon,
  LinkIcon,
  LoaderCircleIcon,
  Volume2Icon,
} from 'lucide-react'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { ArchiveHeader } from './components/ArchiveHeader'
import { useAnalytics } from '@/hooks/useAnalytics'
import { Helmet } from 'react-helmet'
import { usePrevious } from '@/hooks/usePrevious'
import { Button, buttonVariants } from '@/components/ui/button'
import { IArchive } from 'backend/src/interface'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import { useAudioPlayer } from '@/hooks/useAudioPlayer'
import { AppRoutes } from '@/routes'
import { VerticalTimeline } from './components/VerticalTimeline'
import { MarkdownView } from '@/components/MarkdownView'
import { useBoost } from '@/hooks/useBoost'
import { useSse } from '@/hooks/useSse'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'

export function ArchivePage() {
  const [params] = useSearchParams()
  const url = params.get('url') || ''

  const [showPayModal, setPayModal] = useState(false)

  const analytics = useAnalytics()

  const {
    data: archive,
    isLoading: loadingArchive,
    refetch: refetchArchive,
  } = api.archives.view.useQuery(
    { url },
    {
      refetchOnWindowFocus: !showPayModal,
      refetchOnReconnect: !showPayModal,
      refetchOnMount: !showPayModal,
    },
  )

  const enableTtsQuery =
    archive && 'url' in archive && archive.format !== 'mhtml' && !archive.ttsUrl
  const { data: ttsStatus } = api.archives.ttsStatus.useQuery(
    { url },
    {
      enabled: enableTtsQuery,
    },
  )

  const scrapeMutation = api.archives.scrape.useMutation({
    onSettled: () => {
      refetchArchive()
    },
  })

  const { addEventListener, clearEventListeners } = useSse(url)

  const onPayment = async () => {
    toast({ title: 'Payment Received' })

    analytics.sendEvent('payment_made', { url })

    if (!showPayModal) {
      await refetchArchive()
    }
  }

  const onMinted = async () => {
    toast({
      title: 'Minted',
    })

    if (!showPayModal) {
      await refetchArchive()
    }
  }

  const previousShowPayModal = usePrevious(showPayModal)

  useEffect(() => {
    // Need to clear event listeners when the pay modal is opened/closed
    // so we have the latest value of showPayModal
    if (
      typeof previousShowPayModal !== 'undefined' &&
      showPayModal !== previousShowPayModal
    ) {
      clearEventListeners()
    }

    addEventListener('paymentReceived', onPayment)
    addEventListener('minted', onMinted)
    addEventListener('archiveCompleted', async () => await refetchArchive())
    addEventListener('archiveFailed', async () => await refetchArchive())
  }, [addEventListener])

  useEffect(() => {
    if (previousShowPayModal && !showPayModal) {
      refetchArchive()
    }
  }, [showPayModal])

  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'resize' && archive) {
        iframeRef.current?.style.setProperty(
          'height',
          `${event.data.height + 20}px`,
        )
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [archive])

  if (loadingArchive) {
    return (
      <div className="flex justify-center pt-8 lg:pt-14">
        <LoaderCircleIcon className="animate-spin" />
      </div>
    )
  }

  if (archive?.status === 'scraping') {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="flex justify-center">
          <LoaderCircleIcon className="h-8 w-8 animate-spin antialiased" />
        </div>
        <h1 className="text-2xl font-bold">Archiving in Progress</h1>
        <p className="text-muted-foreground mt-2 text-center">
          This page will automatically refresh when the archive is ready.
        </p>
      </div>
    )
  }

  if (archive?.status === 'failed') {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <div className="flex justify-center">
          <CircleXIcon className="h-8 w-8 antialiased" />
        </div>
        <h1 className="text-2xl font-bold">Archiving Failed</h1>
        <p className="text-muted-foreground mt-2 text-center">
          This page failed to archive. Please use the button below to try again.
          If the problem persists, please contact support.
        </p>
        <div className="mt-6">
          <Button onClick={() => scrapeMutation.mutate({ url })}>Retry</Button>
        </div>
      </div>
    )
  }

  if (!archive || archive.status !== 'completed') {
    return <div className="flex justify-center pt-8 lg:pt-14">Not found :(</div>
  }

  let iframeSrc =
    archive.format === 'mhtml'
      ? mhtmlToHtml(archive.textContent || '')
      : undefined

  if (iframeSrc) {
    iframeSrc = iframeSrc.replace(
      '</head>',
      `<script>
        function sendHeight() {
          const height = document.body.scrollHeight;
          window.parent.postMessage({ type: 'resize', height }, '*');
        }

        // Send height when the page loads
        window.addEventListener('load', sendHeight);

        // Optionally, send height when the window is resized
        window.addEventListener('resize', sendHeight);
      </script>
    </head>`,
    )
  }

  const ttsUrl = archive.ttsUrl
    ? archive.ttsUrl
    : archive.format !== 'mhtml' && ttsStatus?.status === 'completed'
      ? ttsStatus.url
      : undefined

  const date = archive.publishedAt
    ? +new Date(archive.publishedAt)
    : archive.date

  return (
    <div className="mx-auto w-full max-w-screen-lg pb-8 pt-8 lg:pb-14 lg:pt-14">
      <Helmet title={`${archive.title ?? archive.url}`} />
      <div className="mb-8">
        <ArchiveHeader
          archive={archive}
          payModal={showPayModal}
          setPayModal={setPayModal}
        />
      </div>

      <a
        href={archive.url}
        target="_blank"
        rel="noreferrer noopener"
        className="mb-1 break-words font-serif text-2xl sm:text-3xl"
      >
        {archive.title ?? archive?.url}
      </a>
      <div className="text-muted-foreground mt-1 flex gap-2 text-sm sm:items-center">
        {archive.author && (
          <>
            <div>{archive.author}</div>
            <div>|</div>
          </>
        )}
        <div>{formatDateLong(date)}</div>
        <div>|</div>
        <div>{formatFileSize(archive.fileSize)}</div>
      </div>
      <div className="mt-8 flex flex-1 items-center gap-2">
        <ButtonGroup archive={archive} ttsUrl={ttsUrl} />
      </div>
      {!ttsUrl && ttsStatus?.status === 'queued' ? (
        <div className="bg-background mt-8 w-full rounded-lg border p-4 text-center text-sm shadow-sm">
          Text-to-speech is being generated, please check back soon. Estimated
          completion time: {ttsStatus.time_left}
        </div>
      ) : null}

      <section className="relative mt-8">
        {archive.format === 'markdown' && (
          <MarkdownView content={archive.textContent} />
        )}

        {archive.format === 'ascii-art' && (
          <div
            style={{ fontFamily: 'monospace' }}
            className="w-full whitespace-pre-wrap [word-break:break-word]"
          >
            {archive.textContent}
          </div>
        )}
        {archive.format === 'plain-text' && (
          <div>
            <div className="w-full whitespace-pre-wrap [word-break:break-word]">
              {archive.textContent}
            </div>
          </div>
        )}
        {archive.format === 'mhtml' && (
          <iframe
            ref={iframeRef}
            srcDoc={iframeSrc}
            className="w-full overflow-hidden"
          />
        )}
      </section>
    </div>
  )
}

const ButtonGroup = ({
  archive,
  ttsUrl,
}: {
  archive: IArchive
  ttsUrl: string | undefined
}) => {
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const analytics = useAnalytics()

  const audioPlayer = useAudioPlayer()
  const { setArchive, archive: currentBoost } = useBoost()

  const hashTimestamp = archive.hashTx?.blockTimestamp
  const fullTextTimestamp = archive.fullTextTx?.blockTimestamp
  const txid = archive.fullTextTx?.txid || archive.hashTx?.txid

  const timelineMarks = [
    {
      date: hashTimestamp ? hashTimestamp * 1000 : undefined,
      label: `Saved on chain${fullTextTimestamp ? ' (hash)' : ''}`,
    },
    {
      date: fullTextTimestamp ? fullTextTimestamp * 1000 : undefined,
      label: `Saved on chain${hashTimestamp ? ' (full text)' : ''}`,
    },
    { date: archive.date, label: 'Archived' },
    {
      date: archive.publishedAt ? +new Date(archive.publishedAt) : undefined,
      label: 'Originally published',
    },
  ]

  const updateBoost = () => {
    if (!txid) return

    setArchive({
      url: archive.url,
      title: archive.title ?? new URL(archive.url).host,
      txid,
      hash: archive.boost?.hash,
    })
  }
  return (
    <>
      {!txid ? null : currentBoost && currentBoost.txid !== txid ? (
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button size="icon" variant="secondary" className="h-8 w-auto px-2">
              <ChevronsUpIcon className="h-4 w-4 flex-shrink-0" />{' '}
              {archive.boost?.score ? archive.boost.score.toFixed(2) : 0}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Switch Boost</AlertDialogTitle>
              <AlertDialogDescription>
                You currently have a boost in progress for another archive.
                Would you like to switch to this archive? All progress will be
                saved for the current boost.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={updateBoost}>
                Continue
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      ) : (
        <Button
          size="icon"
          variant="secondary"
          className="h-8 w-auto px-2"
          onClick={() => {
            setArchive(null)
            updateBoost()
          }}
        >
          <ChevronsUpIcon className="h-4 w-4 flex-shrink-0" />{' '}
          {archive.boost?.score ? archive.boost.score.toFixed(2) : 0}
        </Button>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button size="icon" variant="secondary" className="h-8 w-8">
            <LinkIcon className="h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="ml-4 grid w-full max-w-[calc(100vw-2rem)] grid-cols-[1fr,auto] gap-x-3 sm:max-w-max">
          <a
            href={archive.url}
            target="_blank"
            rel="noreferrer noopener"
            className="text-theme text-sm underline-offset-4 [word-break:break-word] hover:underline"
          >
            {archive.url}
          </a>
          <button
            onClick={() => {
              navigator.clipboard.writeText(archive.url ?? '')
              toast({ title: 'Copied' })
            }}
          >
            <CopyIcon className="h-5 w-5 sm:h-4 sm:w-4" />
          </button>
        </PopoverContent>
      </Popover>

      {ttsUrl && (
        <Button
          size="icon"
          variant="secondary"
          className="h-8 w-8"
          onClick={() =>
            audioPlayer.playUrl(ttsUrl, {
              title: archive.title ?? new URL(archive.url).host,
              artist: archive.url,
              titleLink: AppRoutes.buildArchiveRoute(archive.url),
            })
          }
        >
          <Volume2Icon className="h-4 w-4" />
        </Button>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button size="icon" variant="secondary" className="h-8 w-8">
            <CalendarIcon className="h-4 w-4" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="ml-4 w-auto gap-2 text-sm">
          <VerticalTimeline marks={timelineMarks} />
        </PopoverContent>
      </Popover>
      <Button
        size="icon"
        variant="secondary"
        className="h-8 w-8"
        onClick={() => setShowDownloadModal(true)}
      >
        <DownloadIcon className="h-4 w-4" />
      </Button>

      <Dialog open={showDownloadModal} onOpenChange={setShowDownloadModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Download Archive</DialogTitle>
            <DialogDescription>
              By clicking download you'll download the original text of the
              page. If the archive has been saved on chain, the download will
              also contain a{' '}
              <span className="text-primary font-mono">readme.txt</span> file
              with instructions for how to verify the authenticity of the page.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="space-between flex justify-center gap-2">
            <DialogClose asChild>
              <Button variant="secondary">Cancel</Button>
            </DialogClose>
            <a
              href={`/api/download?url=${archive.url}`}
              className={buttonVariants({})}
              onClick={() =>
                analytics.sendEvent('archive_downloaded', { url: archive.url })
              }
            >
              Download <DownloadIcon className="ml-1.5 h-4 w-4" />
            </a>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
