import { useState, useEffect } from 'react'
import { Card, CardContent } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import {
  Play,
  Pause,
  ChevronUpIcon,
  BitcoinIcon,
  CircleXIcon,
  ArrowLeftIcon,
  ChevronsUpIcon,
} from 'lucide-react'
import { formatDuration, intervalToDuration } from 'date-fns'
import { useRef } from 'react'
import { Progress } from './ui/progress'
import { cn } from '@/lib/utils'
import {
  MainMessage,
  PROGRESS_UPDATE_BATCH,
  WorkerWinnerUpdatedMessage,
  WorkerStartMessage,
} from './boost-worker'
import { EMAILS, LOCAL_STORAGE, PAYMENT_PAYMAIL } from '@/lib/constants'
import { z } from 'zod'
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogTitle,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogFooter,
  AlertDialogDescription,
} from './ui/alert-dialog'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from './ui/dialog'
import { useBoost } from '@/hooks/useBoost'
import PayPlugin from './PayPlugin'
import { api } from '@/lib/api'
import { useSse } from '@/hooks/useSse'
import { toast } from './ui/use-toast'
import { getHashScore, getWinningHash } from '@ark/utils'

let timerInterval: NodeJS.Timeout | null = null
let statsInterval: NodeJS.Timeout | null = null

const POLL_LATEST_BOOST_INTERVAL = 1000 * 60 * 2 // 2 minutes

const STATS_REFRESH_INTERVAL = PROGRESS_UPDATE_BATCH / 20

const bestHashLocalStorageSchema = z.object({
  hash: z.string(),
  txid: z.string(),
  data: z.string(),
})

export function Boost() {
  const { minimized, setMinimized, archive, setArchive } = useBoost()

  const [running, setRunning] = useState(false)
  const [bestHash, setBestHash] = useState('')
  const [bestData, setBestData] = useState('')
  const [hashCount, setHashCount] = useState(0)

  const [hashRate, setHashRate] = useState(0)
  const [runningTime, setRunningTime] = useState(0)

  const workers = useRef<Worker[]>([])

  // Use refs for tracking stats for much better performance
  // we'll update actual state values in a setInterval
  const _bestHash = useRef('')
  const _bestData = useRef('')
  const _lastUpdateTime = useRef(Date.now())
  const _recentHashCount = useRef(0)
  const _hashCount = useRef(0)

  useEffect(() => {
    const workerCount = navigator.hardwareConcurrency
    // const workerCount = 1

    console.log(
      `%cSetting up ${workerCount} workers`,
      'color: white; background: green; padding: 2px 4px; border-radius: 4px;',
    )

    for (let i = 0; i < workerCount; i++) {
      const worker = new Worker(new URL('./boost-worker.ts', import.meta.url), {
        type: 'module',
      })
      worker.onmessage = handleWorkerMessage
      workers.current.push(worker)
    }

    return () => {
      workers.current.forEach((worker) => {
        worker.postMessage({ type: 'stop' })
        worker.terminate()
      })
    }
  }, [])

  const handleWorkerMessage = (e: MessageEvent<MainMessage>) => {
    if (!archive?.txid) return

    if (e.data.type === 'progress') {
      _hashCount.current += e.data.currentHashCount
      _recentHashCount.current += e.data.currentHashCount
    }

    if (e.data.type === 'winner') {
      const { winner, score } = getWinningHash(_bestHash.current, e.data.hash)
      if (winner === e.data.hash && score >= 1) {
        _bestHash.current = e.data.hash
        _bestData.current = e.data.data

        saveBestHash({
          hash: _bestHash.current,
          txid: e.data.txid,
          data: _bestData.current,
        })

        broadcastWinner()
      }
    }
  }

  const updateStats = () => {
    if (!_recentHashCount.current) return

    setHashCount(_hashCount.current)
    setBestHash(_bestHash.current)
    setBestData(_bestData.current)

    const now = Date.now()
    const timeSpan = (now - _lastUpdateTime.current) / 1000

    setHashRate(Math.round(_recentHashCount.current / timeSpan))

    _lastUpdateTime.current = now
    _recentHashCount.current = 0
  }

  const broadcastWinner = () => {
    workers.current.forEach((worker) => {
      worker.postMessage({
        type: 'winnerUpdated',
        winningHash: _bestHash.current,
      } as WorkerWinnerUpdatedMessage)
    })
  }

  const getSavedBestHash = (txid: string) => {
    const raw = localStorage.getItem(LOCAL_STORAGE.bestBoostHash(txid))
    if (!raw) return null

    const parsed = bestHashLocalStorageSchema.safeParse(JSON.parse(raw))
    if (!parsed.success) return null

    return parsed.data
  }

  const saveBestHash = (hash: z.infer<typeof bestHashLocalStorageSchema>) => {
    localStorage.setItem(
      LOCAL_STORAGE.bestBoostHash(hash.txid),
      JSON.stringify(hash),
    )
  }

  const deleteBestHashLocalStorage = () => {
    if (!archive?.txid) throw Error('No txid')

    localStorage.removeItem(LOCAL_STORAGE.bestBoostHash(archive.txid))
  }

  const reset = () => {
    setBestHash('')
    setBestData('')
    setHashCount(0)
    setHashRate(0)
    setRunningTime(0)

    _bestHash.current = ''
    _recentHashCount.current = 0
    _lastUpdateTime.current = Date.now()
    _bestData.current = ''
  }

  const checkLatestScore = async () => {
    if (!archive) return

    const res = await fetch(`/api/boost?url=${archive.url}`)

    // Empty string if no boost found
    const data: { hash: string } = await res.json()

    if (data.hash && archive.hash && data.hash !== archive.hash) {
      toast({
        title: 'Newer boost found!',
        description: 'Target score has been updated.',
      })

      setArchive({
        ...archive,
        hash: data.hash,
      })
    }
  }

  useEffect(() => {
    if (!archive) {
      reset()
      setRunning(false)
      return
    }

    const updateState = async () => {
      reset()

      await checkLatestScore()

      const saved = getSavedBestHash(archive.txid)
      if (saved) {
        setBestHash(saved.hash)
        setBestData(saved.data)
        _bestData.current = saved.data
        _bestHash.current = saved.hash
      }

      if (workers.current.length) {
        broadcastWinner()
      }
    }

    updateState()
  }, [archive])

  useEffect(() => {
    if (running && archive) {
      workers.current.forEach((worker) => {
        worker.postMessage({
          type: 'start',
          txid: archive.txid,
        } as WorkerStartMessage)
      })

      timerInterval = setInterval(() => {
        setRunningTime((prev) => prev + 1)
      }, 1000)

      statsInterval = setInterval(() => {
        updateStats()
      }, STATS_REFRESH_INTERVAL)
    } else {
      workers.current.forEach((worker) => {
        worker.postMessage({ type: 'stop' })
      })

      timerInterval && clearInterval(timerInterval)
      statsInterval && clearInterval(statsInterval)
    }

    return () => {
      timerInterval && clearInterval(timerInterval)
      statsInterval && clearInterval(statsInterval)
    }
  }, [running, archive])

  useEffect(() => {
    const pollLatestBoostInterval = setInterval(
      checkLatestScore,
      POLL_LATEST_BOOST_INTERVAL,
    )

    return () => {
      clearInterval(pollLatestBoostInterval)
    }
  }, [])

  const toggleRunning = () => {
    setRunning((prev) => !prev)
  }

  const winning = !bestHash
    ? false
    : archive?.hash
      ? getWinningHash(archive.hash, bestHash).winner === bestHash
      : true

  const [showPayment, setShowPayment] = useState(false)

  const handleSave = async () => {
    setRunning(false)

    await checkLatestScore()

    setShowPayment((prev) => !prev)
  }

  const { addEventListener, clearEventListeners } = useSse(archive?.url)

  useEffect(() => {
    if (!archive?.url) {
      clearEventListeners()
      return
    }

    addEventListener('boostPaymentSuccess', (data) => {
      if (data.hash === bestHash) {
        deleteBestHashLocalStorage()

        setBestHash('')
        setBestData('')
        setHashCount(0)
        _bestData.current = ''
        _hashCount.current = 0
        _recentHashCount.current = 0

        toast({
          title: 'Boost payment successful!',
          description: `Your boost is currently being saved on chain. Once completed, you'll be able to view the TX details on the archive page.`,
        })
      }
    })

    return () => {
      clearEventListeners()
    }
  }, [archive])

  const score = getHashScore(bestHash)
  return (
    <div className="fixed inset-x-2 bottom-2 z-50 flex justify-end">
      {/* Minimized */}
      <div
        onClick={() => setMinimized(false)}
        className={`bg-card text-card-foreground flex w-full max-w-2xl cursor-pointer items-center justify-between rounded-lg border px-3 py-2 shadow-lg transition-all ${cn({ 'translate-y-full opacity-0': !minimized })}`}
      >
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <span className="flex items-center gap-2">
              <span
                className={`${cn({ 'animate-pulse text-green-500': running, 'text-secondary': !running })}`}
              >
                ●
              </span>{' '}
              Boost
            </span>
          </div>
          <div className="text-muted-foreground flex items-center gap-2 text-sm">
            <ChevronsUpIcon className="h-4 w-4" />
            <span>
              <span className="hidden sm:inline">Score:</span>{' '}
              {score.toFixed(2)}
            </span>
            <span>•</span>
            <span>{hashRate.toLocaleString()} hashes/s</span>
          </div>
        </div>
        <Button variant="ghost" size="sm" onClick={() => setMinimized(false)}>
          <ChevronUpIcon className="h-5 w-5" />
        </Button>
      </div>

      <Dialog open={!minimized} onOpenChange={(open) => setMinimized(!open)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Boost</DialogTitle>
            <DialogDescription>
              Boost content visibility by contributing computing power.
            </DialogDescription>
          </DialogHeader>

          {showPayment && archive?.url && bestHash ? (
            <div>
              <div className="flex">
                <Button variant="ghost" onClick={() => setShowPayment(false)}>
                  <ArrowLeftIcon className="h-4 w-4" /> Back
                </Button>
              </div>

              <div className="mt-4 flex justify-center">
                <Payment url={archive.url} hash={bestHash} data={bestData} />
              </div>
            </div>
          ) : (
            <>
              <Card className="overflow-hidden">
                <CardContent className="space-y-4 pt-6">
                  {archive && (
                    <div className="mb-8">
                      <div className="truncate text-sm font-medium">
                        {archive.title}
                      </div>
                      <div className="text-muted-foreground text-xs">
                        {archive.url}
                      </div>
                    </div>
                  )}
                  <div className="flex items-center justify-between gap-2">
                    {running ? (
                      <Button onClick={toggleRunning} className="w-32">
                        <Pause className="mr-2 h-4 w-4" /> Pause
                      </Button>
                    ) : (
                      <Button onClick={toggleRunning} className="w-32">
                        <Play className="mr-2 h-4 w-4" /> Start
                      </Button>
                    )}
                    <Button
                      variant="theme"
                      disabled={!bestHash || !winning}
                      className="w-32 gap-2"
                      onClick={handleSave}
                    >
                      <BitcoinIcon className="h-5 w-5" />
                      Save
                    </Button>
                  </div>

                  <div className="bg-secondary space-y-3 rounded-lg p-4">
                    <div className="flex items-center justify-between text-sm">
                      <span className="flex items-center gap-2">
                        <ChevronsUpIcon className="h-4 w-4" />
                        Boost Score:
                      </span>
                      <span className="font-bold">
                        <span
                          className={cn({
                            'text-green-500': winning,
                          })}
                        >
                          {score.toFixed(2)}
                        </span>{' '}
                        {archive?.hash &&
                          `/ ${getHashScore(archive.hash).toFixed(2)}`}
                      </span>
                    </div>
                    <Progress
                      value={score}
                      max={20}
                      className="bg-background h-2"
                    />
                  </div>

                  <div className="grid gap-2">
                    <div className="flex justify-between text-sm">
                      <span className="text-muted-foreground font-medium">
                        Processing Speed:
                      </span>
                      <span>{hashRate.toLocaleString()} hashes/sec</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="text-muted-foreground font-medium">
                        Total Hashes:
                      </span>
                      <span>{hashCount.toLocaleString()}</span>
                    </div>
                    <div className="flex justify-between text-sm">
                      <span className="text-muted-foreground font-medium">
                        Running Time:
                      </span>
                      <span>
                        {' '}
                        {runningTime &&
                          formatDuration(
                            intervalToDuration({
                              start: 0,
                              end: runningTime * 1000,
                            }),
                            { delimiter: ', ' },
                          )}
                      </span>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <div className="_gap-2 flex items-center text-sm font-medium">
                      Best Hash:
                    </div>
                    <div className="bg-secondary break-all rounded-md p-2 font-mono text-xs">
                      {bestHash || 'None found yet'}
                    </div>
                  </div>

                  <div className="text-muted-foreground text-xs">
                    The longer you boost, the higher chance of finding a better
                    hash and increasing content's visibility.
                  </div>
                </CardContent>
              </Card>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button variant="secondary">
                    <CircleXIcon className="h-4 w-4" /> Hide Boost Dialog
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Stop Boost?</AlertDialogTitle>
                    <AlertDialogDescription>
                      This will stop any current boosts and hide the boost
                      dialog. You can always re-open it by clicking the boost
                      icon in the archive page.
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => {
                        setRunning(false)
                        setArchive(null)
                      }}
                    >
                      Continue
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

function Payment({
  url,
  hash,
  data,
}: {
  url: string
  hash: string
  data: string
}) {
  const { data: fee } = api.ordinals.estimateBoost.useQuery({
    url,
    data,
  })

  const callbackUrl = new URL(
    `${window.location.origin}/api/callbacks/payment/boost`,
  )

  callbackUrl.searchParams.set('hash', hash)
  callbackUrl.searchParams.set('data', data)
  return (
    <div>
      {fee?.usd && (
        <PayPlugin
          paymail={PAYMENT_PAYMAIL}
          callbackUrl={callbackUrl.toString()}
          sessionId={url}
          productName={`Ark Boost - ${url}`}
          price={fee.usd}
          receiptEmail={EMAILS.RECEIPT}
        />
      )}
    </div>
  )
}
